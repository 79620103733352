<template>
  <div class="home">
    <van-nav-bar
      title="IN ROOM DINING"
      :left-arrow="false"
      @click-left="onClickLeft"
    >
      <!-- <template #left>
        <img src="@/assets/img/菜单.png" alt="" style="height: 25pt" />
      </template> -->
      <template #right>
        <img
          src="@/assets/img/全部订单.png"
          @click="goMyOrder"
          alt=""
          style="height: 19.5pt"
        />
      </template>
    </van-nav-bar>

    <div class="section-area">
      <van-icon
        class="arrow-left"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow-left"
      />
      <div class="section">
        <div
          class="item"
          v-for="(mealPeriod, index) in mealPeriodList"
          :key="index"
          @click="chooseType(mealPeriod)"
        >
          <img class="icon" src="@/assets/img/早餐.png" />
          <div
            class="top-text"
            :class="{ activeType: active === mealPeriod.id }"
          >
            {{ mealPeriod.mealPeriod }}
          </div>
          <div class="top-time">
            <!-- <img src="@/assets/img/时间.png" alt="" style="height: 10pt" /> -->
            <span>{{ mealPeriod.fromTime + '-' + mealPeriod.toTime }}</span>
          </div>
        </div>
      </div>
      <van-icon
        class="arrow-right"
        v-if="mealPeriodList.length > 3"
        size="15pt"
        name="arrow"
      />
    </div>

    <div class="content">
      <van-collapse v-model="activeName">
        <van-collapse-item
          v-for="(item, index) in categoryList"
          :key="index"
          :title="item.categoryName"
          :name="item.id"
        >
          <div
            v-for="(food, i) in item.mainDishList"
            :key="i"
            :class="
              i == item.mainDishList.length - 1 ? 'item no-border' : 'item'
            "
            @click="showFoodInfo(food)"
          >
            <div class="name">
              <span>{{ food.name }}</span>
              <img
                v-if="
                  food.type &&
                  food.type.split(',').indexOf('GF (Gluten Free)') != -1
                "
                @click.stop="seeToast('Gluten Free')"
                src="../assets/img/1.png"
              />
              <img
                v-if="
                  food.type &&
                  food.type.split(',').indexOf('DF (Dairy Free)') != -1
                "
                @click.stop="seeToast('Dairy Free')"
                src="../assets/img/2.png"
              />
              <img
                v-if="
                  food.type && food.type.split(',').indexOf('VG (Vegan)') != -1
                "
                @click.stop="seeToast('Vegetarian')"
                src="../assets/img/3.png"
              />
              <img
                v-if="
                  food.type &&
                  food.type.split(',').indexOf('V (Vegetarian)') != -1
                "
                @click.stop="seeToast('Vegetarian')"
                src="../assets/img/4.png"
              />
            </div>
            <div class="price">
              <span>${{ food.fullPrice }}</span>
            </div>
            <div class="info">
              <span>
                {{ food.description }}
              </span>
            </div>
            <div class="add">
              <van-badge v-if="food.num > 0" :content="food.num">
                <img class="img" src="../assets/img/添加.png" alt="" />
              </van-badge>
              <img v-else class="img" src="../assets/img/添加.png" alt="" />
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div
        style="
          font-size: 10pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #636464;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        "
        @click="showInfo"
      >
        <span style="text-decoration: underline">
          Disclaims - Click to see details
        </span>
      </div>
    </div>

    <div class="bottom">
      <div style="display: flex; align-items: center">
        <img src="../assets/img/购物车.png" alt="" @click="placeOrder" />
        <span class="price">${{ allPrice.toFixed(2) }}</span>
      </div>
      <div class="btn" @click="placeOrder">
        <span>MY ORDER</span>
      </div>
    </div>

    <van-popup
      class="foodModel"
      :close-on-click-overlay="false"
      v-model="foodInfoShow"
      round
      position="bottom"
      :lazy-render="true"
      :style="{ height: '50%' }"
    >
      <div>
        <div class="title">
          <span>{{ selectFood.name }}</span>
          <van-icon @click="close" class="icon" size="15pt" name="cross" />
        </div>

        <div class="item">
          <div class="item-name">
            <span>Quantity</span>
          </div>
          <van-stepper v-model="selectFood.num" min="0" integer />
        </div>
        <div
          class="item"
          v-if="selectFood.halfPrice != null && selectFood.halfPrice != 0"
        >
          <div class="item-name">
            <span>Half Or Full</span>
          </div>
          <van-radio-group class="radio-group" v-model="selectFood.isFull">
            <van-radio :name="false" icon-size="12pt">
              Half &nbsp;&nbsp;
              <span>${{ selectFood.halfPrice }}</span>
            </van-radio>
            <van-radio :name="true" icon-size="12pt">
              Full &nbsp;&nbsp;
              <span>${{ selectFood.fullPrice }}</span>
            </van-radio>
          </van-radio-group>
        </div>
        <div
          class="item"
          v-for="(item, index) in selectFood.foods"
          :key="index"
        >
          <div class="item-name">
            <span>{{ item.title }}</span>
          </div>
          <van-radio-group
            v-if="item.type == 'Radio'"
            class="radio-group"
            v-model="item.checked"
          >
            <van-radio
              v-for="food in item.optionList"
              :key="food.id"
              :name="food.id"
              icon-size="12pt"
              >{{ food.name }} &nbsp;&nbsp;
              <span v-if="food.price != 0"
                >+&nbsp;${{ food.price }}</span
              ></van-radio
            >
          </van-radio-group>
          <van-checkbox-group
            v-else
            class="checked-group"
            v-model="item.checked"
            @change="checkedChange"
          >
            <van-checkbox
              v-for="food in item.optionList"
              :key="food.id"
              shape="square"
              icon-size="12pt"
              :name="food.id"
              >{{ food.name }} &nbsp;&nbsp;
              <span v-if="food.price != 0"
                >+&nbsp;${{ food.price }}</span
              ></van-checkbox
            >
          </van-checkbox-group>
        </div>
        <div
          class="item"
          v-if="selectFood.drinks && selectFood.drinks.length > 0"
        >
          <div class="item-name">
            <span>Beverage</span>
          </div>
          <div class="select">
            <div
              v-for="(item, index) in selectFood.drinks"
              :key="index"
              :class="item.checked ? 'type-checked' : 'type'"
              @click="choseDrink(item.id)"
            >
              <span>{{ item.title }}</span>
            </div>
          </div>
          <van-radio-group
            class="radio-group"
            v-model="selectFood.drinksChildrenChecked"
          >
            <van-radio
              v-for="item in selectFood.drinksChildren"
              :key="item.id"
              :name="item.id"
              icon-size="12pt"
              >{{ item.name }}&nbsp;&nbsp;<span v-if="item.price != 0"
                >+&nbsp;${{ item.price }}</span
              >
            </van-radio>
          </van-radio-group>
        </div>
        <div class="remark">
          <div class="item-name">
            <span>Special Introduce</span>
          </div>
          <van-field
            class="input"
            v-model="selectFood.remark"
            rows="1"
            autosize
            type="textarea"
          />
          <van-button
            class="btn"
            color="#2b2e36"
            size="large"
            @click="addShoppingFood"
            >ADD</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from '@/api/manage'
import { mapGetters, mapActions } from 'vuex'
import { randomUUID } from '@/utils/utils'
import { Toast } from 'vant'
import lodash from 'lodash'
import { Dialog } from 'vant'
import moment from 'moment'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      foodInfoShow: false,
      url: {
        list: '/adminApi/foodOrder/list',
        login: '/api/common/user/hotel_dept_name/login',
        getUserInfo: '/api/common/user/current_user',
        hotelQuery: '/api/sys_admin/hotel/query',
        getDisclaimerByHotelId: '/adminApi/foodOrder/getDisclaimerByHotelId',
      },
      mealPeriodList: [],
      activeName: [],
      selectFood: {}, //弹窗的餐品信息
      allPrice: 0, //购物车总价格
      active: 'breakfast',
      week: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
      categoryList: [],
      hotelDeptName: 'janedu',
      hotelName: 'Hotel2',
      passWord: '888888',
    }
  },
  computed: {
    ...mapGetters({
      shoppingFood: 'foods', //购物车中的餐品
    }),
  },
  watch: {
    // 深度 watcher
    shoppingFood: {
      handler: function (val, oldVal) {
        console.log(val)
        this.allPrice = 0
        for (let i = 0; i < val.length; i++) {
          const element = val[i]
          this.allPrice += element.allPrice
        }
      },
      deep: true,
    },
  },
  created() {
    // console.log(this.shoppingFood)
    for (let i = 0; i < this.shoppingFood.length; i++) {
      const element = this.shoppingFood[i]
      this.allPrice += element.allPrice
    }
    if (localStorage.getItem('HTTOKEN')) {
      this.initData()
      this.getUserInfo()
      this.hotelQuery()
      this.getDisclaimer()
    } else {
      this.login()
    }
  },
  methods: {
    moment,
    ...mapActions(['addFood', 'removeFood']),
    login() {
      postAction(this.url.login, {
        hotelDeptName: this.hotelDeptName,
        hotelName: this.hotelName,
        password: this.passWord,
      }).then((res) => {
        localStorage.setItem('hotelName', this.hotelName)
        localStorage.setItem('HTTOKEN', res.data.token)
        localStorage.setItem(
          'loginInfo',
          JSON.stringify({
            hotelDeptName: this.hotelDeptName,
            hotelName: this.hotelName,
            password: this.passWord,
          })
        )
        this.getUserInfo()
        this.hotelQuery()
        this.getDisclaimer()
        // this.getDict()
      })
    },
    getDisclaimer() {
      getAction(this.url.getDisclaimerByHotelId, {
        hotelId: localStorage.getItem('hotelId'),
      }).then((res) => {
        this.disclaimer = res.data.disclaimer
      })
    },
    getUserInfo() {
      getAction(this.url.getUserInfo).then((res) => {
        localStorage.setItem('hotelId', res.data.hotelId)
        localStorage.setItem('username', res.data.username)
        localStorage.setItem('hotelDeptId', res.data.hotelDeptId)
        localStorage.setItem('hotelDeptName', res.data.hotelDeptName)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.initData()
      })
    },
    hotelQuery() {
      postAction(this.url.hotelQuery, {
        hotelNo: localStorage.getItem('hotelName'),
      }).then((res) => {
        localStorage.setItem('timeZone', res.data.timeZone)
        localStorage.setItem('activeTime', res.data.activeTime)
      })
    },
    seeToast(value) {
      Toast(value)
    },
    goMyOrder() {
      this.$router.push('/myOrder')
    },
    chooseType(mealPeriod) {
      var now = new Date()
      let date = moment(now.getTime()).format('YYYY-MM-DD')
      var fromTime = moment(date + ' ' + mealPeriod.fromTime).valueOf()
      var toTime = moment(date + ' ' + mealPeriod.toTime).valueOf()
      if (fromTime > now || toTime < now) {
        Toast("it's not open time")
      }
      if (mealPeriod.id != this.active) {
        this.active = mealPeriod.id
        for (let i = 0; i < this.mealPeriodList.length; i++) {
          const element = this.mealPeriodList[i]
          if (element.id == mealPeriod.id) {
            this.categoryList = element.categoryList
          }
        }
      }
    },
    initData() {
      this.mealPeriodList = []
      var param = {
        hotelId: localStorage.getItem('hotelId'),
      }
      getAction(this.url.list, param).then((res) => {
        if (res.msg == 'SUCCESS') {
          //解析类别
          var now = new Date()
          this.mealPeriodList = []
          let date = moment(now.getTime()).format('YYYY-MM-DD')
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i]
            if (element.type) {
              //如果是open状态
              let flag = false
              let day = this.week[now.getDay()]
              if (element.day.indexOf(day) != -1) {
                flag = true
              }
              if (element.date.indexOf(date) != -1) {
                flag = true
              }
              if (flag) {
                this.mealPeriodList.push(element)
              }
            } else {
              //如果是close状态
              let flag = true
              let day = this.week[now.getDay()]
              if (element.day.indexOf(day) != -1) {
                flag = false
              }
              if (element.date.indexOf(date) != -1) {
                flag = false
              }
              if (flag) {
                this.mealPeriodList.push(element)
              }
            }
          }
          console.log(this.mealPeriodList)
          //数量初始化
          for (let i = 0; i < this.mealPeriodList.length; i++) {
            const mealPeriod = this.mealPeriodList[i]
            for (let j = 0; j < mealPeriod.categoryList.length; j++) {
              const category = mealPeriod.categoryList[j]
              for (let k = 0; k < category.mainDishList.length; k++) {
                const food = category.mainDishList[k]
                food.num = 0
                //数据初始化
                food.foods = []
                food.drinks = []
                food.drinksChildren = []
                food.drinksChildrenChecked = ''
                food.isFull = true
                //区分饮品和其他
                for (let i = 0; i < food.sideDishList.length; i++) {
                  const element = food.sideDishList[i]
                  if (element.isBeverage) {
                    food.drinks.push(element)
                  } else {
                    food.foods.push(element)
                  }
                }
                for (let i = 0; i < food.foods.length; i++) {
                  const element = food.foods[i]
                  if (element.optionList.length > 0) {
                    if (element.type == 'Radio') {
                      element.checked = element.optionList[0].id
                    } else {
                      element.checked = [element.optionList[0].id]
                    }
                  }
                }
                //饮品处理
                for (let i = 0; i < food.drinks.length; i++) {
                  const element = food.drinks[i]
                  if (i == 0) {
                    element.checked = true
                  } else {
                    element.checked = false
                  }
                }
                if (food.drinks.length > 0) {
                  food.drinksChildren = food.drinks[0].optionList
                  if (food.drinksChildren.length > 0) {
                    food.drinksChildrenChecked = food.drinksChildren[0].id
                  }
                }
              }
            }
          }
          if (this.mealPeriodList.length > 0) {
            this.active = this.mealPeriodList[0].id
            this.categoryList = this.mealPeriodList[0].categoryList
            if (this.categoryList.length > 0) {
              this.activeName = [this.categoryList[0].id]
            }
          }
        }
      })
    },
    choseDrink(val) {
      var target = null
      for (let i = 0; i < this.selectFood.drinks.length; i++) {
        const item = this.selectFood.drinks[i]
        item.checked = false
        if (item.id == val) {
          target = item
        }
      }
      if (target) {
        target.checked = true
        this.selectFood.drinksChildren = target.optionList
        if (this.selectFood.drinksChildren.length > 0) {
          this.selectFood.drinksChildrenChecked =
            this.selectFood.drinksChildren[0].id
        }
      }
    },
    onClickLeft() {},
    showFoodInfo(food) {
      this.selectFood = lodash.cloneDeep(food)
      this.selectFood.num = 1
      this.selectFood.remark = ''
      this.foodInfoShow = true
    },
    addShoppingFood() {
      if (this.selectFood.num > 0) {
        var food = {
          id: randomUUID(), //订单餐品id
          foodId: this.selectFood.id, //餐品id
          drinks: this.selectFood.drinks, //所有饮品类别及其下饮品
          drinksChildren: this.selectFood.drinksChildren, //饮品类别下的饮品
          drinksChildrenChecked: this.selectFood.drinksChildrenChecked, //饮品类别下的选择的饮品
          foods: this.selectFood.foods, //所有食物
          remark: this.selectFood.remark,
          hotelId: this.selectFood.hotelId,
          name: this.selectFood.name,
          isFull: this.selectFood.isFull,
          fullPrice: this.selectFood.fullPrice,
          halfPrice: this.selectFood.halfPrice,
          sideDishList: this.selectFood.sideDishList,
          allPrice: 0,
          num: this.selectFood.num,
        }
        //计算价格
        for (let i = 0; i < food.drinksChildren.length; i++) {
          const drink = food.drinksChildren[i]
          if (drink.id == food.drinksChildrenChecked) {
            food.allPrice += Number(drink.price)
          }
        }
        for (let i = 0; i < food.foods.length; i++) {
          const element = food.foods[i]
          for (let j = 0; j < element.optionList.length; j++) {
            const item = element.optionList[j]
            if (element.type == 'Radio') {
              if (item.id == element.checked) {
                food.allPrice += Number(item.price)
              }
            } else {
              if (element.checked.indexOf(item.id) != -1) {
                food.allPrice += Number(item.price)
              }
            }
          }
        }
        if (food.isFull) {
          food.allPrice += Number(food.fullPrice)
        } else {
          food.allPrice += Number(food.halfPrice)
        }
        food.allPrice = food.allPrice * food.num
        this.addFood(food)
        // this.selectFood.num++
        for (let i = 0; i < this.categoryList.length; i++) {
          const category = this.categoryList[i]
          for (let j = 0; j < category.mainDishList.length; j++) {
            const food = category.mainDishList[j]
            if (food.id == this.selectFood.id) {
              food.num += this.selectFood.num
            }
          }
        }
        this.foodInfoShow = false
      }
    },
    close() {
      this.foodInfoShow = false
    },
    placeOrder() {
      this.$router.push({ path: '/placeOrder' })
    },
    checkedChange(value) {
      if (value.length > 3) {
        Toast('Select up to three')
        value.pop()
        return
      }
    },
    showInfo() {
      Dialog.alert({
        message: this.disclaimer,
        confirmButtonText: 'Confirm',
      }).then(() => {
        // on close
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;
  /deep/.van-nav-bar {
    background-color: #000;
    height: 35pt;
    width: 100%;
    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }
  }
  /deep/.van-grid-item__content {
    background: #f5f4f4;
    // padding: 0.42667rem 0.11333rem;
    // padding: 0.42667rem 3.75pt;
    padding: 17pt 3.75pt 7.5pt;
  }
  /deep/.van-nav-bar__content {
    height: 100%;
  }

  .section-area {
    position: relative;
    width: calc(100% - 20pt);
    margin: 5pt 10pt;
    height: 84pt;
    .section {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      overflow-x: auto;
      width: 100%;

      .item {
        flex-shrink: 0;
        width: 31%;
        display: flex;
        flex-flow: column;
        align-content: center;
        justify-content: center;
        margin-right: 3%;
        .icon {
          margin-top: 5pt;
          align-self: center;
          width: 30pt;
        }
        .top-text {
          align-self: center;
          margin-top: 5pt;
          font-size: 10pt;
          font-weight: bold;
          padding: 4pt;
          border-radius: 3pt;
          width: fit-content;
          white-space: nowrap;
        }
        .top-time {
          align-self: center;
          display: flex;
          background: #ededed;
          margin-top: 5pt;
          padding: 5pt;
          font-size: 8pt;
          border-radius: 3pt;
          white-space: nowrap;
        }
        .activeType {
          align-self: center;
          color: #fff;
          background: #000;
        }
      }

      .item:last-child {
        margin: 0;
      }
    }

    .arrow-left {
      position: absolute;
      left: -5%;
      top: 40%;
    }

    .arrow-right {
      position: absolute;
      right: -5%;
      top: 40%;
    }
  }

  .content {
    height: calc(100% - 184pt);
    width: calc(100% - 20pt);
    padding: 0 10pt;
    text-align: left;
    overflow: auto;

    .van-collapse-item--border::after {
      border-top: none;
    }

    .van-collapse-item {
      margin-bottom: 10pt;
    }

    /deep/.van-cell {
      padding: 10pt 16pt;
    }

    /deep/.van-cell__title,
    .van-cell__value {
      font-size: 13pt;
      font-family: ArialRoundedMTBold;
      color: #2b2e36;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    /deep/.van-collapse-item__content {
      padding-top: 0;
      padding-bottom: 0;
    }

    .item {
      position: relative;
      border-bottom: 1px dashed #dbdada;
      padding: 10pt 0;
      .name {
        font-size: 11pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        // font-weight: 600;
        color: #2b2e36;
        display: flex;
        align-items: center;
        width: 180pt;

        img {
          width: 15pt;
          height: 15pt;
          background-repeat: no-repeat;
          margin-left: 5.5pt;
        }
      }

      .price {
        font-size: 10pt;
        font-family: ArialNarrow;
        color: #2b2e36;
        padding-bottom: 2pt;
      }

      .info {
        font-size: 10pt;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636464;
      }

      .add {
        position: absolute;
        right: 0;
        top: 10%;

        img {
          width: 15pt;
          height: 15pt;
          background-repeat: no-repeat;
        }
      }
    }

    .no-border {
      border: 0pt;
    }
  }

  .bottom {
    height: 35pt;
    width: calc(100% - 20pt);
    background: #ffffff;
    border-radius: 25pt;
    margin: 10pt 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 20pt;
      height: 20pt;
      background-repeat: no-repeat;
      margin-left: 20pt;
      margin-right: 10pt;
    }

    .price {
      font-size: 13pt;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #2e2e2e;
    }

    .btn {
      width: 120pt;
      height: 35pt;
      background: #2b2e36;
      border-radius: 25pt;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .foodModel {
    text-align: left;
    background: #f9f9f9;
    .title {
      background-color: #ffffff;
      padding: 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }

    .item {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;
      margin-bottom: 2pt;
      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .checked-group {
        /deep/.van-checkbox__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/.van-checkbox__icon--checked .van-icon {
          background-color: #686868;
          border-color: #686868;
        }
      }

      .radio-group {
        /deep/.van-radio__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/.van-radio__icon--checked .van-icon {
          background-color: #686868;
          border-color: #686868;
        }
      }

      .select {
        display: flex;
        align-items: center;
        margin-bottom: 5pt;
        .type {
          width: 60pt;
          height: 20pt;
          background: #ffffff;
          border-radius: 3.5pt;
          border: 1pt solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;
          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
          }
        }

        .type-checked {
          // background: #f7f7f7;
          background: rgba(0, 0, 0, 0.7);
          border: 1pt solid #333333;
          width: 60pt;
          height: 20pt;
          border-radius: 3.5pt;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;
          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #2b2e36;
            color: #ffffff;
          }
        }
      }
    }

    .remark {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;
      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .input {
        margin-top: 5pt;
        margin-bottom: 10pt;
        border-radius: 1pt;
        border: 1pt solid #979797;
      }

      .btn {
        height: 30pt;
        border-radius: 5pt;
        /deep/.van-button__text {
          font-size: 12pt;
        }
      }
    }
  }
}
</style>
