import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RequestHotelReceipt from "../views/RequestHotelReceipt.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "RequestHotelReceipt",
    component: RequestHotelReceipt,
    meta: { title: "Request Hotel Receipt" },
  },
  // {
  //   path: "/confirmOrder",
  //   name: "ConfirmOrder",
  //   component: () => import("@/views/ConfirmOrder"),
  //   meta: { title: "Long Beach Hotel" },
  //   hidden: true,
  // },
  // {
  //   path: "/placeOrder",
  //   name: "PlaceOrder",
  //   component: () => import("@/views/PlaceOrder"),
  //   meta: { title: "Long Beach Hotel" },
  //   hidden: true,
  // },
  // {
  //   path: "/myOrder",
  //   name: "Order",
  //   component: () => import("@/views/Order"),
  //   meta: { title: "Long Beach Hotel" },
  //   hidden: true,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  //   meta: { title: "About" },
  // },
  {
    path: "/404",
    name: "Page404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
