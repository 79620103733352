<template>
  <div class="receipt">
    <div class="header">
      <div class="header-content">
        <div style="color: red">* Required</div>
        <div>A copy of receipt will be emailed to the email</div>
        <div v-if="deptId">
          address on file from the reservation, in {{ receiptNum }} business
          {{ receiptUnit }}<span v-if="receiptNum > 1">s</span>.
        </div>
        <div v-else>address on file (from the reservation)</div>
      </div>
    </div>
    <div class="content">
      <div class="form-content">
        <el-form
          ref="requestForm"
          :model="requestForm"
          :rules="requestRule"
          size="small"
          class="form"
        >
          <el-form-item
            prop="hotel"
            label="Select Your Hotel:"
            class="bottom-line"
          >
            <el-select
              v-model="requestForm.hotel"
              class="hotel"
              size="small"
              filterable
              remote
              :remote-method="getHotelList"
              @blur="placeholderrm = 'City,State or Brand'"
              style="width: 100%"
              placeholder="City,State or Brand"
              no-data-text="Hotel not found.  Please try again"
              ref="select"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
              :popper-append-to-body="false"
              @change="getToken"
            >
              <el-option
                v-for="item in hotels"
                :key="item.hotelId"
                :label="item.hotelName"
                :value="item.hotelId"
              >
                <span
                  style="
                    float: left;
                    margin-top: 0px;
                    margin-left: 25px;
                    width: 250px;
                    text-align: left;
                    white-space: pre-line;
                    line-height: 15px;
                  "
                  >{{ item.hotelName }}</span
                >
                <span
                  style="
                    float: left;
                    color: #8492a6;
                    font-size: 10px;
                    display: block;
                    margin-top: -8px;
                    margin-left: 25px;
                  "
                  >{{ item.city }},{{ item.state }}</span
                >
                <img
                  id="u239_img"
                  class="img a"
                  style="width: 20px; position: absolute; left: 10px; top: 0px"
                  src="https://d1icd6shlvmxi6.cloudfront.net/gsc/T9NQ25/8e/fd/71/8efd712fe5ee4f3abef8826d2c99e7de/images/index_search/u239.png"
                />
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="firstName" label="First Name:" class="one-line">
            <el-input v-model="requestForm.firstName"> </el-input>
          </el-form-item>
          <el-form-item prop="lastName" label="Last Name:" class="one-line">
            <el-input v-model="requestForm.lastName"> </el-input>
          </el-form-item>
          <el-form-item
            prop="confirmation"
            label="Confirmation #"
            class="one-line"
          >
            <el-input v-model="requestForm.confirmation"> </el-input>
          </el-form-item>
          <el-form-item prop="checkIn" label="Check-in Date:" class="one-line">
            <el-date-picker
              v-model="requestForm.checkIn"
              format="MM/dd/yyyy"
              :clearable="false"
              placeholder=""
              :picker-options="setDateRange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="email" label="Email:" class="one-line">
            <el-input v-model="requestForm.email"> </el-input>
          </el-form-item>
          <el-form-item prop="note" label="Comments/Special Instructions:">
            <el-input
              v-model="requestForm.note"
              type="textarea"
              placeholder="Add a note"
              :rows="row"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="check">
          <el-checkbox v-model="checkBox"></el-checkbox>
          <span style="line-height: 15px; font-size: 12px"
            >I hereby confirm that I am a registered guest and/or I am
            authorized by the individual or entity responsible for the charges
            in the requested receipt.</span
          >
        </div>
        <div
          style="text-align: left; position: relative; display: flex"
          class="submit"
        >
          <span
            v-if="showErro"
            style="
              position: absolute;
              left: -5px;
              top: -3px;
              color: red;
              font-size: 12px;
              font-size: 12px;
              transform: scale(0.83, 0.83);
            "
          >
            <i class="el-icon-warning" style="color: red;mar"></i>
            &nbsp;Authorization is required in order to receive your
            receipt.</span
          >
          <div class="button" @click="saveTicket" style="text-align: center">
            Request My Receipt
          </div>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
    <el-dialog
      :visible.sync="showmodal"
      width="264px"
      :show-close="false"
      :modal="true"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="modal_content">
        <div class="title">Thank you!</div>
        <div style="line-height: 13px; margin: 5px 0px 10px">
          Your request has been received. A copy of your receipt will be emailed
          to the email address we have on file from the reservation.
        </div>
        <div class="content">
          <div style="display: flex">
            <div class="content-title">Hotel:</div>
            <div>{{ hotelName }}</div>
          </div>
          <div>
            <span class="content-title">Check-in Date:</span
            ><span>{{ moment(requestForm.checkIn).format("MM/DD/yyyy") }}</span>
          </div>
          <div>
            <span class="content-title">Last Name:</span
            ><span>{{ requestForm.lastName }}</span>
          </div>
        </div>
        <div class="button" @click="closeModal">OK</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import moment from "moment-timezone";
export default {
  name: "RequestHotelReceipt",
  components: {},
  data() {
    return {
      hotels: [],
      showmodal: false,
      timeZone: "",
      showErro: false,
      hotelName: "",
      hotelNo: "",
      deptId: "",
      requestForm: {
        hotel: "",
        firstName: "",
        lastName: "",
        confirmation: "",
        checkIn: null,
        email: "",
        note: "",
      },
      requestRule: {
        hotel: [
          {
            required: true,
            message: " ",
          },
        ],
        firstName: [
          {
            required: true,
            message: " ",
          },
        ],
        lastName: [
          {
            required: true,
            message: " ",
          },
        ],
        checkIn: [
          {
            required: true,
            message: " ",
          },
        ],
        email: [
          {
            required: true,
            message: " ",
            type: "email",
          },
        ],
      },
      url: {
        login: "/api/common/user/hotel_dept_name/login",
        hotelList: "/adminApi/hotelinfo/selectHotelInfoList",
        create: "/api/common/hotel/data/multipleorder/create",
        deptList: "/api/common/hotel/data/service_dept/by_hotel/",
        receiptTime: "/adminApi/servicetime/listbyhotel",
      },
      checkBox: false,
      receiptNum: 0,
      receiptUnit: "",
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        },
      },
      row: 3,
    };
  },
  computed: {},
  watch: {},
  created() {
    if (window.outerWidth > 876) {
      this.row = 2;
    }
  },
  methods: {
    moment,
    getToken() {
      for (let element of this.hotels) {
        if (this.requestForm.hotel == element.hotelId) {
          this.hotelName = element.hotelName;
          this.hotelNo = element.hotelNo;
          this.timeZone = element.timeZone;
        }
      }
      let params = {
        hotelDeptName: "qrbot",
        hotelName: this.hotelNo,
        password: "123456",
      };
      postAction(this.url.login, params).then((res) => {
        localStorage.setItem("HTTOKEN", res.data.token);
      });
      //获取deptId
      let url = this.url.deptList + this.hotelNo;
      getAction(url).then((res) => {
        for (let dept of res.data) {
          if ("FrontOffice" == dept.deptName) {
            this.deptId = dept.hotelDeptId;
          }
        }
      });
      this.getReceiptTime();
    },
    getHotelList(key) {
      getAction(this.url.hotelList, {
        key: key,
      }).then((res) => {
        this.hotels = res.data;
      });
    },
    getReceiptTime() {
      getAction(this.url.receiptTime, {
        hotelId: this.requestForm.hotel,
        type: "external",
      }).then((res) => {
        for (let element of res.data) {
          if ("Receipt" == element.keywords) {
            this.receiptNum = element.beforeStart;
            this.receiptUnit = element.beforeStartUnit;
          }
        }
      });
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          input.blur();
        }
      });
    },
    saveTicket() {
      this.$refs["requestForm"].validate((valid) => {
        if (valid) {
          if (this.checkBox) {
            let param = {
              ticket: {
                requests: [
                  {
                    requestTime: moment
                      .tz(
                        moment(new Date())
                          .tz(this.timeZone)
                          .format("YYYY-MM-DD HH:mm")
                      )
                      .utc()
                      .format(),
                    hotelId: this.requestForm.hotel,
                    hotelDeptId: this.deptId,
                    confirmation: this.requestForm.confirmation,
                    serviceKey: "Receipt",
                    staffResponse: false,
                    serviceType: "",
                    notes: this.requestForm.note,
                    email: this.requestForm.email,
                    checkInDate: moment(this.requestForm.checkIn).format(
                      "MM/DD/yyyy"
                    ),
                    firstName: this.requestForm.firstName,
                    lastName: this.requestForm.lastName,
                  },
                ],
              },
            };
            postAction(this.url.create, param).then((res) => {
              if (res.status == "0") {
                this.showmodal = true;
              } else {
                this.$message.error("creat failed.");
              }
            });
          } else {
            this.showErro = true;
          }
        }
      });
    },
    closeModal() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  word-break: normal;
  background: #f2f2f2;
  height: 755px;
  @media (min-width: 876px) {
    height: 685px;
  }
  /deep/.el-form-item__label:before {
    color: #000 !important;
  }
  /deep/.el-select-dropdown__item {
    height: auto;
    min-height: 45px;
  }
  /deep/.el-input__inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 3px;
  }
  /deep/.ant-input {
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border-radius: 3px;
  }
  /deep/.el-date-editor {
    width: 100%;
  }
  /deep/.el-select-dropdown {
    left: 0px !important;
  }
  /deep/.el-select-dropdown__list {
    padding: 15px 0px;
  }
  /deep/.el-select-dropdown__item.selected {
    // color:#606266;
    font-weight: 500;
  }
  /deep/.el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #fff;
  }
  width: 400px;
  margin: 0 auto;
  @media (max-width: 576px) {
    width: 100%;
  }
  .header {
    position: relative;
    background: url("../assets/img/无白框.png");
    background-size: 100% auto;
    height: 165px;
    background-repeat: no-repeat;
    text-align: left;
    line-height: 12px;
    .header-content {
      position: absolute;
      top: 65px;
      left: 7px;
      @media (max-width: 576px) {
        top: 16vw;
        left: 1.5vw;
      }
      div {
        font-size: 12px;
        transform: scale(0.83, 0.83);
      }
    }
  }
  .content {
    padding: 0px 15px;
    z-index: 99;
    position: absolute;
    width: 400px;
    // height: calc(100% - 160px);
    height: 600px;
    top: 116px;
    @media (max-width: 576px) {
      // height: calc(100% - 40vw);
      width: 100%;
      top: 29vw;
    }
    @media (min-width: 876px) {
      height: 530px;
    }
    .form-content {
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 20px;
      padding-top: 13px;
      @media (min-width: 876px) {
        padding-top: 5px;
      }
      .bottom-line {
        padding-bottom: 9px;
        border-bottom: 1px solid #cccc;
        margin-bottom: 9px;
        @media (min-width: 876px) {
          padding-bottom: 5px;
          margin-bottom: 5px !important;
        }
      }
      .one-line {
        display: flex;
        /deep/.el-form-item__label {
          width: 45%;
          text-align: left;
        }
        /deep/.el-form-item__content {
          width: 60%;
        }
      }
    }
  }
  .check {
    text-align: left;
    display: flex;
    /deep/.el-checkbox {
      margin-right: 10px;
    }
  }
  .button {
    height: 30px;
    background: #ff6633;
    line-height: 30px;
    margin-top: 30px;
    color: #fff;
    padding: 0px 10px;
    width: 208px;
    margin-left: calc(50% - 104px);
    border-radius: 4px;
  }
  .bottom {
    position: absolute;
    // bottom: 0px;
    top: 655px;
    width: 400px;
    @media (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 876px) {
      top: 585px;
    }
    background: url("../assets/img/无白框底.png");
    background-size: 100% auto;
    height: 100px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 876px) {
  /deep/.el-form-item {
    margin-bottom: 9px !important;
  }
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  padding: 20px 25px;
}
.modal_content {
  font-size: 12px;
  word-break: normal;
  color: #000;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .content {
    background: #f2f2f2;
    padding: 5px 10px;
    .content-title {
      font-weight: 700;
      margin-right: 5px;
    }
  }
  .button {
    text-align: center;
    height: 30px;
    background: #ff6633;
    line-height: 30px;
    margin-top: 10px;
    color: #fff;
    padding: 0px 10px;
    width: 214px;
    margin-left: calc(50% - 107px);
    border-radius: 4px;
  }
}
/deep/.el-dialog {
  margin-top: 25vh !important;
}
body {
  background: #f2f2f2;
}
</style>
