const food = {
  state: {
    foods: [],
    order: [],
  },
  mutations: {
    ADD_FOOD: (state, data) => {
      state.foods.push(data);
    },
    REMOVE_FOOD: (state, data) => {
      state.foods = state.foods.filter((item) => {
        return item.id != data;
      });
    },
    EDIT_FOOD: (state, data) => {
      state.foods.find((item) => {
        return item.id != data.id;
      });
    },
    ADD_ORDER: (state, data) => {
      state.order = data;
      state.foods = [];
    },
  },
  actions: {
    addFood: ({ commit }, data) => {
      commit("ADD_FOOD", data);
    },
    removeFood: ({ commit }, data) => {
      commit("REMOVE_FOOD", data);
    },
    editFood: ({ commit }, data) => {
      commit("REMOVE_FOOD", data);
    },
    addOrder: ({ commit }, data) => {
      commit("ADD_ORDER", data);
    },
  },
};

export default food;
